<template>
  <v-container fluid id="pageContainer"
      v-resize="onResize"
      :style="`height:${maxViewHeight}px`">
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-card>
          <v-card-title ref="pageTitle">Medusa Campaign Management Tool</v-card-title>
          <v-card flat ref="tableActions">
            <v-container cols="12" fluid>
              <v-row align="end">
                <v-col class="pt-0" cols="auto">
                  <v-menu 
                    transition="slide-y-transition"
                    :close-on-content-click="false"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Filter
                      </v-btn>
                    </template>
                    <v-card class="pa-2">
                      <!-- Status Filter -->
                      <v-row dense align="center">
                        <v-col cols="auto">
                          <v-tooltip bottom open-delay="300">
                            <template #activator="{ on }">
                              <v-btn
                                :depressed="toggleStatusFilter"
                                @click="toggleFilter('status', statusFilterChanged)"
                                v-on="on"
                              >
                                <v-icon class="pr-1">
                                  {{
                                    statusFilterChanged
                                      ? `mdi-filter-plus-outline`
                                      : `mdi-filter-remove`
                                  }}
                                </v-icon>
                                Status
                              </v-btn>
                            </template>
                            <span>
                              {{ 
                                statusFilterChanged 
                                  ? 'Select All'
                                  : 'Select None'
                              }}
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-col>
                          <v-list flat class="py-0">
                            <v-list-item-group
                              multiple
                              v-model="activeFilters.status"
                            >
                              <v-row no-gutters>
                                <v-col
                                  v-for="(statusFilter, index) in filters.status"
                                  :key="index"
                                  cols="auto"
                                >
                                  <v-list-item :value="statusFilter.value">
                                    <template #default="{ active }">
                                      <v-list-item-action class="my-1 mr-1">
                                        <v-checkbox :input-value="active"></v-checkbox>
                                      </v-list-item-action>

                                      <v-list-item-content :class="[`py-0`, statusFilter.labelClass]">
                                        <v-list-item-title>{{
                                          statusFilter.label
                                        }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-list-item>
                                </v-col>
                              </v-row>
                            </v-list-item-group>
                          </v-list>
                        </v-col>
                      </v-row>
                      <!-- Country Filter -->
                      <v-row dense align="center">
                        <v-col cols="auto">
                          <v-tooltip bottom open-delay="300">
                            <template #activator="{ on }">
                              <v-btn
                                :depressed="toggleCountryFilter"
                                @click="toggleFilter('countries', countriesFilterChanged)"
                                v-on="on"
                              >
                                <v-icon class="pr-1">
                                  {{
                                    countriesFilterChanged
                                      ? `mdi-filter-plus-outline`
                                      : `mdi-filter-remove`
                                  }}
                                </v-icon>
                                Country
                              </v-btn>
                            </template>
                            <span>
                              {{ 
                                countriesFilterChanged 
                                  ? 'Select All'
                                  : 'Select None'
                              }}
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-col>
                          <v-list flat class="py-0">
                            <v-list-item-group
                              multiple
                              v-model="activeFilters.countries"
                            >
                              <v-row no-gutters>
                                <v-col
                                  v-for="(countryFilter, index) in filters.countries"
                                  :key="index"
                                  cols="auto"
                                >
                                  <v-list-item :value="countryFilter">
                                    <template v-slot:default="{ active }">
                                      <v-list-item-action class="my-1 mr-1">
                                        <v-checkbox :input-value="active"></v-checkbox>
                                      </v-list-item-action>

                                      <v-list-item-content class="py-0">
                                        <v-list-item-title>{{
                                          countryFilter
                                        }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-list-item>
                                </v-col>
                              </v-row>
                            </v-list-item-group>
                          </v-list>
                        </v-col>
                      </v-row>
                      <!-- Promote filter -->
                      <v-row dense align="center">
                        <v-col cols="auto">
                          <v-tooltip bottom open-delay="300">
                            <template #activator="{ on }">
                              <v-btn
                                :depressed="toggleLOPromoteFilter"
                                @click="toggleFilter('promote', promoteFilterChanged)"
                                v-on="on"
                              >
                                <v-icon class="pr-1">
                                  {{
                                    promoteFilterChanged
                                      ? `mdi-filter-plus-outline`
                                      : `mdi-filter-remove`
                                  }}
                                </v-icon>
                                Promotion
                              </v-btn>
                            </template>
                            <span>
                              {{ 
                                promoteFilterChanged 
                                  ? 'Select All'
                                  : 'Select None'
                              }}
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-col>
                          <v-list flat class="py-0">
                            <v-list-item-group
                              multiple
                              v-model="activeFilters.promote"
                            >
                              <v-row no-gutters>
                                <v-col
                                  v-for="(promoteFilter, index) in filters.promote"
                                  :key="index"
                                  cols="auto"
                                >
                                  <v-list-item :value="promoteFilter.value">
                                    <template v-slot:default="{ active }">
                                      <v-list-item-action class="my-1 mr-1">
                                        <v-checkbox :input-value="active"></v-checkbox>
                                      </v-list-item-action>

                                      <v-list-item-content class="py-0">
                                        <v-list-item-title>{{
                                          promoteFilter.label
                                        }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-list-item>
                                </v-col>
                              </v-row>
                            </v-list-item-group>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col class="py-0" align="start">
                  <v-alert
                    v-if="Object.keys(changedFilters).length > 0"
                    dense
                    text
                    tile
                    type="info"
                    class="py-1 mb-3"
                  > 
                    <v-list 
                      light
                      dense
                      class="py-0"
                    >
                      <div
                        class="subtitle-2 filter-info-text"
                      >
                        Excluding:
                      </div>
                      <v-list-item
                        v-for="(values, name) in changedFilters"
                        :key="name"
                        dense
                        class="text-caption filter-info-text"
                      >
                        {{ name.toUpperCase() }} = {{ values.join(', ') }}
                      </v-list-item>
                    </v-list>
                    
                  </v-alert>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card tile flat>
            <v-overlay
              z-index="3"
              absolute
              :value="showExpandedLineorder"
            ></v-overlay>
            <v-data-table
              :headers="headers"
              :items="tableItems"
              item-class="status"
              :loading="listLoading"
              :search="search"
              :height="tableHeight"
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              @page-count="pageCount = $event"
              @click:row="expandLineorder"
              @pagination="updatePaginationText"
              @current-items="updateCurrentItems"
              id="campaignTable"
              fixed-header
              class="elevation-2"
              hide-default-footer
              dense
              item-key="loId"
            >
              <template #item.loId="{item}">
                <v-row justify="center" :id="`loId-`+item.loId">{{ item.loId }}</v-row>
              </template>
              <template #item.description="{item}">
                <v-menu
                  :max-width="descriptionColWidth"
                  :disabled="!truncatedDescriptionCells[item.loId]"
                  max-height=200
                  nudge-left="2"
                  open-delay="500"
                  bottom
                  open-on-hover
                >
                  <template #activator="{ on, attrs }">
                    <v-row
                      justify="center"
                      :class="[`pa-1`, `my-0`, `truncate-cell`, `descriptionCell`]"
                      :id="descriptionCellPrefix+item.loId"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{item.description}}
                    </v-row>
                  </template>
                  <v-card
                    :class="[`pl-3`, `pr-2`,`pt-1`, `pb-3`]"  
                  >
                    <v-row 
                      :class="[item.status,`expanded-cell`]"
                    >
                      <v-col class="pb-1">
                      {{ item.description }}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-menu>
              </template>
              <template #item.cpe="{item}">
                <v-row justify="end">{{ formatDollars(item.cpe) }}</v-row>
              </template>
              <template #item.needPerDay="{item}">
                <v-row justify="end">{{ formatDollars(item.needPerDay) }}</v-row>
              </template>
              <template #item.priority="{item}">
                <v-row justify="center">{{ item.priority }}</v-row>
              </template>
              <template #item.limitation="{item}">
                <v-menu
                  :max-width="limitationColWidth"
                  max-height=200
                  nudge-left="2"
                  open-delay="500"
                  bottom
                  open-on-hover
                  :disabled="!truncatedLimitationCells[item.loId]"

                >
                  <template #activator="{ on, attrs }">
                    <v-row 
                      justify="start" 
                      :class="[`pa-1`, `my-0`, `truncate-cell`, `limitationCell`]"
                      :id="limitationCellPrefix+item.loId"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.limitation.replaceAll(",", ", ") }}
                    </v-row>
                  </template>
                  <v-card
                    :class="[`pl-3`, `pr-2`,`pt-1`, `pb-3`]"  
                  >
                    <v-row 
                      :class="[item.status,`expanded-cell`]"
                    >
                      <v-col class="pb-1">
                      {{ item.limitation.replaceAll(",", ", ") }}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-menu>
              </template>
              <template #item.startDate="{item}">
                <v-row justify="center">{{ formatDate(item.startDate) }}</v-row>
              </template>
              <template #item.endDate="{item}">
                <v-row justify="center">{{ formatDate(item.endDate) }}</v-row>
              </template>
              <template #item.promote="{item}">
                <v-btn
                  :class="[item.promote == `0` ? `notPromoted` : ``, `py-1`]"
                  :depressed="item.promote == `1`"
                  @click.stop="toggleLOPromote(item)"
                  :color="item.promote == `1` ? `green accent-4` : `light-grey`"
                  elevation="5"
                >
                  <v-icon>{{
                    item.promote == `1` ? `mdi-currency-usd` : `mdi-currency-usd-off`
                  }}</v-icon>
                </v-btn>
              </template>
              <template #item.copyPromo="{item}">
                <v-row justify="center" class="pt-1 my-0">
                  <v-chip
                    :color="defaultChipColor"
                    small
                  >
                    {{ item.docsWithCopy + ` of ` + item.totalDocs }}
                  </v-chip>
                  /
                </v-row>
                <v-row justify="center" class="py-1 my-0">
                  <v-chip
                    :dark="getDocPromoColor(item.promotedDocCount) != defaultChipColor"
                    :color="getDocPromoColor(item.promotedDocCount)"
                    small
                  >
                    {{
                      item.promotedDocCount > 0
                        ? item.promotedDocCount + ` of ` + item.totalDocs
                        : `No Promo Docs`
                    }}
                  </v-chip>
                </v-row>
              </template>
            </v-data-table>
            <v-navigation-drawer
              v-model="showExpandedLineorder"
              @input="updateUrlRoute"
              temporary
              right
              absolute
              hide-overlay
              width="80%"
              :style="`height: `+tableHeight+`px;`"
            >
              <MedusaCampaignDocuments
                :lineorder="expandedLineorder"
                :key="expandedLineorder.loId"
                :maxHeight="tableHeight"
                @closeExpansion="showExpandedLineorder=false"
              ></MedusaCampaignDocuments>
            </v-navigation-drawer>
          </v-card>
          <!-- Footer -->
          <v-card tile flat ref="tableFooter">
            <v-overlay
              z-index="3"
              absolute
              :value="showExpandedLineorder"
            ></v-overlay>
            <v-container cols="12" fluid>
              <v-row>
                <v-col cols="1">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageOptions"
                    label="Items Per Page"
                    hide-details
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="auto">
                  {{ pageStart + `-` + pageStop + ` of ` + tableItemCount }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="5">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    id="pagination"
                  ></v-pagination>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn
                    :loading="saving"
                    :disabled="listLoading || !isEditing"
                    @click.prevent="save"
                    color="success"
                    class="mr-1"
                  >
                    <v-icon>mdi-content-save</v-icon>
                    Save
                  </v-btn>
                  <v-btn
                    :loading="reseting"
                    :disabled="listLoading || !isEditing"
                    @click.prevent="resetPromotions"
                    color="grey"
                    :dark="!listLoading && isEditing"
                  >
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import MedusaCampaignDocuments from "@/components/medusa/MedusaCampaignDocuments";

export default {
  components: {
    MedusaCampaignDocuments
  },
  data() {
    return {
      currentItems: [],
      expandedLineorder: {},
      showExpandedLineorder: false,
      tableHeight: 520,
      saving: false,
      reseting: false,
      filterHoverDelay: 100,
      toggleStatusFilter: false,
      toggleCountryFilter: false,
      toggleLOPromoteFilter: false,
      defaultChipColor: "grey lighten-2",
      tableItems: [],
      expanded: [],
      detailsLoading: false,
      search: "",
      editedLineorders: new Set(),
      maxViewHeight: 0,
      limitationColWidth: 0,                    // Current limitation column width
      descriptionColWidth: 0,
      truncatedLimitationCells: {},             // map where key is loId and value is if limitation value is truncated on screen
      truncatedDescriptionCells: {},
      descriptionCellPrefix: 'descriptionCell-',
      limitationCellPrefix: 'limitationCell-',  // Limitation cell id prefix
      page: 1,
      pageCount: 0,
      pageStart: 1,
      pageStop: 20,
      itemsPerPageOptions: [
        {text: 5, value: 5},
        {text: 10, value: 10},
        {text: 20, value: 20},
        {text: 'All', value: -1}
        ],
      locations: this.$store.state.auth.user.locations,
      filters: {
        status: [
          { label: "Ahead", value: "ahead", labelClass: "aheadLabel" },
          { label: "Delivered", value: "delivered", labelClass: "deliveredLabel" },
          { label: "In Need", value: "inNeed", labelClass: "inNeedLabel" }
        ],
        countries: this.$store.state.auth.user.locations,
        promote: [
          { label: "Promoted", value: "1" },
          { label: "Not Promoted", value: "0" }
        ]
      },
      defaultActiveFilters: {
        status: ["ahead", "inNeed", "delivered"],
        countries: this.$store.state.auth.user.locations,
        promote: ["1", "0"]
      },
      headers: [
        { text: "Line Order #", value: "loId", divider: true, width: "118px", align: "center" },
        {
          text: "Description",
          value: "description",
          divider: true,
          align: "center",
          width: "170px",
          sortable: false
        },
        { text: "Goal", value: "leadGoal", divider: true, align: "right", width: "78px" },
        { text: "CPE", value: "cpe", divider: true, align: "right", width: "74px" },
        { text: "$ Need/Day", value: "needPerDay", divider: true, align: "right", width: "115px" },
        { text: "Need", value: "need", divider: true, align: "right", width: "80px" },
        { text: "Priority", value: "priority", divider: true, align: "center", width: "91px" },
        {
          text: "Limitation",
          value: "limitation",
          divider: true,
          align: "center",
          sortable: false
        },
        { text: "Start", value: "startDate", divider: true, align: "center", width: "78px" },
        { text: "End", value: "endDate", divider: true, align: "center", width: "79px" },
        {
          text: "Promote",
          value: "promote",
          divider: true,
          align: "center",
          sortable: false,
          width: "80px",
          filter: (value) => this.promoteFilter(value)
        },
        { text: "Copy / Promo", value: "copyPromo", divider: true, sortable: false, width: "87px" },
        {
          text: "Status",
          value: "status", 
          align: " d-none", // ' d-none' hides the column but keeps the search/filter ability
          filter: (value) => this.statusFilter(value)
        },
        {
          text: "Country",
          value: "country",
          align: " d-none", // ' d-none' hides the column but keeps the search/filter ability
          filter: (value) => this.countryFilter(value)
        }
      ]
    };
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  mounted() {
    this.onResize();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm("Leave without saving changes?")) {
        return;
      }
    }
    next();
  },
  // Lifecycle hook
  created() {
    // Initialize search to lineorderNumber in route on initial page load (if present)
    this.search = this.lineorderNumber;
    this.populateTableFromApi();
    // deep copy default active filters for later comparison and 'select all' option
    for (const [key, value] of Object.entries(this.defaultActiveFilters)) {
      // Only set default values if the filer is null (if it's not null, it's from localstorage)
      if (this.activeFilters[key] == null)
        this.activeFilters[key] = [...value];
    }
  },
  watch: {
    // Deep watch activeFilters and explicitly set the activeFilters object in vuex so that it's picked up by vuex-persistedstate
    activeFilters: {
      handler: function(newVal) {
        this.setActiveFilters(newVal);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      activeFilters() {
        return this.$store.state.medusaCampaigns.activeFilters;
      },
      lineorderNumber() {
        return this.$route.params.lineorderNumber;
      },
      editedLODocs() {
        return this.$store.state.medusaCampaigns.editedLODocs;
      },
      listLoading() {
        return this.$store.state.medusaCampaigns.listLoading;
      },
      isEditingLO() {
        return this.$store.state.medusaCampaigns.isEditingLO;
      },
      isEditingDocs() {
        return Object.keys(this.editedLODocs).length > 0;
      },
      isEditing() {
        return this.isEditingLO || this.isEditingDocs;
      },
      statusFilterChanged() {
        return this.defaultActiveFilters.status.length != this.activeFilters.status.length;
      },
      countriesFilterChanged() {
        return this.defaultActiveFilters.countries.length != this.activeFilters.countries.length;
      },
      promoteFilterChanged() {
        return this.defaultActiveFilters.promote.length != this.activeFilters.promote.length;
      },
      tableItemCount() {
        return this.tableItems.length;
      }
    }),
    changedFilters() {
      let changedFilters = {};
      for (const [name, filter] of Object.entries(this.defaultActiveFilters)) {
        let changedOptions = filter
          .filter(option => !this.activeFilters[name].includes(option))
          .map((option) => name == 'countries' ? option : this.filters[name][this.filters[name].findIndex(obj => obj.value==option)]?.label);
        if (changedOptions && changedOptions.length) {
          changedFilters[name] = changedOptions;
        }
      }
      return changedFilters;
      },
    itemsPerPage: {
      get() {
        return this.$store.state.medusaCampaigns.itemsPerPage;
      },
      set(value) {
        this.setItemsPerPage(value);
      }
    },
  },
  methods: {
    ...mapMutations({
      setListLoading: "medusaCampaigns/setListLoading",
      setIsEditingLO: "medusaCampaigns/setIsEditingLO",
      setItemsPerPage: "medusaCampaigns/setItemsPerPage",
      setActiveFilters: "medusaCampaigns/setActiveFilters"
    }),
    // Stores currenty displayed table items data var and updates truncatedCells arrays
    updateCurrentItems(currentItems) {
      this.currentItems = currentItems;
      this.updateTruncatedCells();
    },
    // Calculates current width of limitation column, and sets it to data variable
    calcTruncatedColWidths() {
      let limitationCellEl = document.getElementsByClassName("limitationCell")[0];
      let descriptionCellEl = document.getElementsByClassName("descriptionCell")[0];
      if (limitationCellEl && descriptionCellEl) {
        this.limitationColWidth = limitationCellEl.clientWidth;
        this.descriptionColWidth = descriptionCellEl.clientWidth;
      }
    },
    updateUrlRoute(lineorderExpanded) {
      if (!lineorderExpanded) {
        let yPos = window.visualViewport.pageTop; // current horizontal scroll position
        this.$router.replace({
          name: "Campaign Management",
          params: {
            scrollBehavior: { x: 0, y: yPos, behavior: 'smooth' } 
          }
        });
      }
    },
    updatePaginationText(paginationObj) {
      this.pageStart = this.page == 1 ? 1 : paginationObj.pageStart;
      this.pageStop = paginationObj.pageStop;
    },
    expandLineorder(lineorder) {
      this.expandedLineorder = lineorder;
      let yPos = window.visualViewport.pageTop; // current horizontal scroll position
      if (this.expandedLineorder.loId !== this.lineorderNumber) {
        this.$router.replace({
          params: {
            lineorderNumber: this.expandedLineorder.loId,
            scrollBehavior: { x: 0, y: yPos, behavior: 'smooth' }
          }
        });
      }
      this.showExpandedLineorder = true;
    },
    populateTableFromApi() {
      this.setListLoading(true);
      this.$axios.get("/medusa/lineorder/list").then((res) => {
        this.setListLoading(false);
        this.tableItems = res.data;
      }).finally(()=>{
        // Must be in finally to prevent bug causing lineorder document data not to be cached
        if (this.lineorderNumber) {
          this.expandLineorder(this.findLoIdItem(this.lineorderNumber));
        }
        this.$nextTick(function () {
          this.onResize();
        })
      });
    },
    // Keep track of loId of rows that have had promotion changed
    toggleLOPromote(item) {
      // Toggle item promotion
      item.promote = item.promote == `1` ? `0` : `1`;
      if (this.editedLineorders.has(item.loId)) this.editedLineorders.delete(item.loId);
      else this.editedLineorders.add(item.loId);
      this.setIsEditingLO(this.editedLineorders.size > 0);
    },
    updateTruncatedCells() {
      for (const item of this.currentItems) {
        let loId = item.loId;
        let limitationEl = document.getElementById(this.limitationCellPrefix+loId);
        let descriptionEl = document.getElementById(this.descriptionCellPrefix+loId);
        // Exit early if elements aren't loaded yet
        if (limitationEl == null || descriptionEl == null) 
          return;
        this.truncatedLimitationCells[loId] = limitationEl.offsetHeight < limitationEl.scrollHeight;
        this.truncatedDescriptionCells[loId] = descriptionEl.offsetHeight < descriptionEl.scrollHeight;
      }
    },
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    getDocPromoColor(promotedCount) {
      return promotedCount > 0 ? this.defaultChipColor : "red";
    },
    formatDollars(number) {
      return "$" + (Math.round(number * 100) / 100).toFixed(2);
    },
    formatDate(date) {
      if (date) {
        moment.locale();
        return moment(String(date)).format("ll");
      }
    },
    statusFilter(value) {
      return this.activeFilters.status ? this.activeFilters.status.includes(value) : true;
    },
    countryFilter(value) {
      return this.activeFilters.countries ? this.activeFilters.countries.includes(value) : true;
    },
    promoteFilter(value) {
      return this.activeFilters.promote ? this.activeFilters.promote.includes(value) : true;
    },
    save() {
      this.saving = true;
      //Submit Data
      this.axios
        .put("/medusa/lineorder/updatePromotions", {
          lineorders: this.tableItems,
          loDocs: this.editedLODocs
        })
        .then(() => {
          // Display success/error message
          this.$store.commit("sendAlert", {
            msg: "Promotions Updated",
            color: "success"
          });
          this.populateTableFromApi();
          /** Purge Caches */
          // Combine lons from editedLineOrders and editedLODocs in a set to prevent duplicates
          let lonToPurge = new Set([...this.editedLineorders, ...Object.keys(this.editedLODocs)]);
          /* Purge lineOrderCache */
          this.$store.dispatch("medusaCampaigns/purgeMedusaCache", { cacheName: "lineOrderCache", keys: [...lonToPurge].join("|"), keysSupplement: null });
          /* Purge dmsFilterCache */
          let dmsFilterKeys = "";
          // add loId for lineorders whose promos have been changed to dmsFilter keys to be purged
          for (const loId of lonToPurge) {
            dmsFilterKeys += "lineOrder," + loId + "|";
          }
          dmsFilterKeys += "all";
          this.$store.dispatch("medusaCampaigns/purgeMedusaCache", { cacheName: "dmsFilterCache", keys: dmsFilterKeys, keysSupplement: null });
          /* Purge dmsAssetCache */
          // Only need to purge this cache if document promotions have been changed
          if (this.isEditingDocs) {
            for (const lon of Object.keys(this.editedLODocs)) {
              // Must make multiple calls due to using supplement (which only accepts one at a time)
              this.$store.dispatch("medusaCampaigns/purgeMedusaCache", { cacheName: "dmsAssetCache", keys: null, keysSupplement: "lineorderNumber:" + lon });
            }
          }
          // Reset editing state
          this.$store.dispatch("medusaCampaigns/resetEditedLODocs");
          this.editedLineorders.clear();
          this.setIsEditingLO(false);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    toggleFilter(filterName, changed) {
      if (changed) {
        this.activeFilters[filterName] = [...this.defaultActiveFilters[filterName]];
      } else {
        this.activeFilters[filterName] = [];
      }
    },
    // Adjusts component heights on window resize such that the table footer/headers remain
    // in place and viewable, while the table rows fill the remaining space
    onResize() {
      // Height of page title div
      var pageTitleHeight = this.$refs.pageTitle.clientHeight;
      // Height of table actions div
      var tableActionsHeight = this.$refs.tableActions.$el.clientHeight;
      // Height of table footer
      var tableFooterHeight = this.$refs.tableFooter.$el.clientHeight;
      // Total vertical padding around page content
      var containerVerticalPadding = 12;
      // Height of top tool bar
      var toolBarHeight = document.getElementById('appToolbar').clientHeight;
      // Calculate remaining vertical viewing space after subtracting toolbar and padding heights
      this.maxViewHeight = window.innerHeight - toolBarHeight - containerVerticalPadding;
      // Calculate remaining vertical viewing space for table 
      var calculatedTableHeight = this.maxViewHeight - pageTitleHeight - tableActionsHeight - tableFooterHeight;
      // Don't set table height to anything below 200px to prevent the table from being inaccessible if on a small enough screen
      this.tableHeight = calculatedTableHeight > 200 ? calculatedTableHeight : 200;
      this.calcTruncatedColWidths();
      this.updateTruncatedCells();
    },
    resetPromotions() {
      this.reseting = true;
      this.$store.dispatch("medusaCampaigns/resetDocPromotions");
      for (const item of this.tableItems) {
        if (this.editedLineorders.has(item.loId)) {
          item.promote = item.promote == "1" ? "0" : "1";
          this.editedLineorders.delete(item.loId);
        }
      }
      this.setIsEditingLO(this.editedLineorders.size > 0);
      this.reseting = false;
    },
    findLoIdItem(loId) {
      for (const item of this.tableItems) {
        if (item.loId == loId) {
          return item;
        }
      }
      return null;
    }
  }
};
</script>

<style scoped lang="scss">
/* Make cursor a pointer when hovering table rows, excluding for the expanded documents table */
::v-deep .v-data-table tr:not(#docTable) {
  cursor: pointer;
  max-height: 100px;
}

/* Center checkboxs in column */
::v-deep .v-input__slot {
  align-items: center;
  justify-content: center;
}

/* Inherit max height from rows to restrict cell text height */
::v-deep .v-data-table td {
  max-height: inherit;
}

/* Add vertical overfly scrolling */
.truncate-cell {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.expanded-cell {
  overflow-y: scroll;
  // max-height: 200px;
  // width: fit-content;
  text-align: center;
  font-size: 0.875rem; // Match table font-size
}

.aheadLabel {
  color: #54ae5f;
}

::v-deep .ahead {
  background-color: #54ae5f;
}

/* 10% darker than #54AE5F */
::v-deep .ahead:hover:not(.expanded-cell) {
  background-color: #479450 !important;
}

.deliveredLabel {
  color: #aaa;
}

::v-deep .delivered {
  background-color: #aaa;
}

/* 10% darker than #AAA */
::v-deep .delivered:hover:not(.expanded-cell) {
  background-color: #919191 !important;
}

.v-list-item {
  min-height: auto;
}

.notPromoted {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.filter-info-text {
  color: #2196f3 !important;
  font-weight: bold;
}

/* Set z-index to be above overlay, when present */
.lineorderDetails {
  z-index: 2;
}

::v-deep #pagination {
  button {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}
</style>
